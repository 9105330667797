header {
    padding: 24px 5% !important;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 90%;
    z-index: 98;
    text-align: left;
    display: flex;
    justify-content: space-between;
    background-color: white;
    align-items: center;
}

.header_h1 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin: 0;
    font-weight: bold;
    unicode-bidi: isolate;
}

.burger-menu-button {
    border: 0;
    background: transparent;
    font-size: 26px;
}

.em {
    background: #222222;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 4px;
}

.menu-items-container {
    padding-top: 20px;
    padding-left: 20px;
    position: fixed;
    right: 0;
    width: 90%;
    z-index: 999;
    background-color: white;
    top: 0;
    bottom: 0;
    animation: slideIn 0.5s forwards;
}
@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}
.menu-items-container.closed {
    animation: slideOut 1s forwards;
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}
.menu-items-container ul {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.menu-items-container ul a {
    text-decoration: none;

}

.background {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(34, 34, 34, 0.32);
    z-index: 100;
}

div > ul > li > a {
    font-family: "Karla";
    color: #444444;
    text-decoration: none;
}

div > ul > li > a:hover {
    color: #555555;
}

div > ul > li > a.active {
    color: #ed8374;
    font-weight: bold;
}

div > ul > li > a.active:hover {
    color: #ed8374;
    font-weight: bold;
}

.menu-items-container footer {
    position: fixed;
    bottom: 20px;
}

.close {
    background-color: transparent;
    z-index: 1000;
    display: flex;
    position: absolute;
    right: 20px;
    border: 0;
}