nav {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 100;
}

nav ul {
    display: flex;
    max-width: 90vw;
    justify-content: center;
    flex-wrap: wrap;
    list-style-type: none;
    gap: 40px;
    padding: 0;
}

nav ul li a {
    font-family: "Karla";
    color: #444444;
    text-decoration: none;
}

nav ul li a:hover {
     color: #555555;
}

a.active {
    color: #ed8374;
    font-weight: bold;
}

a.active:hover {
    color: #ed8374;
    font-weight: bold;
}