#writings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.writings_container {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-auto-columns: auto;
}

.div1 {
    fill: #F3F3F3;
}

.prediv1 {
    grid-area: 1 / 1 / 2 / 2;
}

.prediv1 img,
.prediv2 img,
.prediv3 img,
.prediv4 img,
.prediv5 img{
    width: 100%;
    aspect-ratio: 3/1;
}

.prediv2 {
    grid-area: 1 / 2 / 2 / 3;
}

.prediv3 {
    grid-area: 1 / 3 / 2 / 4;
}

.prediv4 {
    grid-area: 2 / 2 / 3 / 3;
}

.prediv5 {
    grid-area: 2 / 3 / 3 / 4;
}

.prediv1, .prediv2, .prediv3, .prediv4, .prediv5 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


.prediv1 a, .prediv2 a, .prediv3 a, .prediv4 a, .prediv5 a {
    text-decoration: none;
    color: inherit;
}

p.px {
    font-size: 13px;
    max-width: 90%;
    font-weight: 500;
    text-decoration: none;
}

.prediv1:hover, .prediv2:hover, .prediv3:hover, .prediv4:hover, .prediv5:hover {
    color: gray;
    transition: 0.5s ease-in-out;
}

#writings > header {
    display: none;
}

@media screen and (max-width: 768px) {
    #writings > header {
        display: flex;
    }
    nav {
        display: none;
    }
    #writings > h1 > a {
        display: none;
    }
    .writings_container {
        width: 90%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        grid-auto-columns: auto;
    }

    .prediv1 {
        grid-area: 1 / 1 / 2;
    }

    .prediv2 {
        grid-area: 1 / 2;
    }

    .prediv3 {
        grid-area: 2 / 2;
    }

    .prediv4 {
        grid-area: 3 / 2;
    }

    .prediv5 {
        grid-area: 2 / 1;
    }

}