#video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.video_content {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 40px
}

.video_content > iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
}
#video > header {
    display: none;
}

#video > div:nth-child(16) {
    width: 90%;
    text-align: center;
}


#video > div:nth-child(16) > p > a {
    color: #282c34;

}

@media screen and (max-width: 768px) {
    #video > header {
        display: flex;
    }
    nav {
        display: none;
    }
    #video > h1 > a {
        display: none;
    }
}