footer {
    display: flex;
    gap: 20px;
}

footer a:hover {
    color: #555555;
}

footer a {
    color: #999999;
    font-size: 11.7px;
    font-weight: normal;
    text-decoration: none;
    display: inline-block;
}

p.footer {
    color: #343434;
    font-family: karla, sans-serif;
    font-size: 11px;
    font-style: normal;
}