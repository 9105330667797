#contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.contact_text {
    display: flex;
    flex-direction: column;
    max-width: 50%;
}

/*form*/
.contact_form {
    display: flex;
    flex-direction: column;
}

.contact_form div {
    display: flex;
}

.contact_form div input,
.contact_form textarea {
    padding: 12px 14px;
    border: 1px solid rgba(52, 52, 52, 0.3);
    font-size: 16px;
    font-family: Karla;
}

.contact_form div input {
    width: 50%;
}

.send_button {
    padding: 15px 30px;
    border: 1px solid rgba(52, 52, 52, 0.3);
    text-decoration: none;
    width: 15%;
    display: flex;
    justify-content: center;
    color: #282c34;
}

#contact > header {
    display: none;
}

@media screen and (max-width: 768px) {
    #contact > header {
        display: flex;
    }
    nav {
        display: none;
    }
    #about > h1 > a {
        display: none;
    }
}