#news {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.logo a {
    text-decoration: none;
    color: black;
}

iframe {
    width: 80%;
    aspect-ratio: 16 / 9; /* Пропорції ширини до висоти вашого відео (16:9) */
}

#news > header {
    display: none;
}

@media screen and (max-width: 768px) {
    #news > header {
        display: flex;
    }
    nav {
        display: none;
    }
    #about > h1 > a {
        display: none;
    }
}