#press {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

#press .press_content {
    display: flex;
    gap: 20vw;
    max-width: 90%;
}

.eightcol_last div p a {
    color: #282c34;
    font-weight: 400;
}

.eightcol_last div p a:hover {
    color: gray;
}

#press > header {
    display: none;
}

@media screen and (max-width: 768px) {
    #press > header {
        display: flex;
    }
    nav {
        display: none;
    }
    #press > h1 > a {
        display: none;
    }
}

@media screen and (max-width: 425px) {
    #press .press_content{
        display: flex;
        flex-direction: column;
        gap: 5vh;
    }
}