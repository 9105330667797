#about {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.about_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 80%;
}

.black {
    color: #343434;
    font-family: karla, sans-serif;
    font-size: 17px;
    font-weight: bold;
}

.gray {
    color: #343434;
    font-family: karla, sans-serif;
    font-size: 17px;
    line-height: 1.5;
}

#about > header {
    display: none;
}

@media screen and (max-width: 768px) {
    #about > header {
        display: flex;
    }
    nav {
        display: none;
    }
    #about > h1 > a {
        display: none;
    }
}